










































import Vue from 'vue'
import request from '@/utils/request'
const columns = [
  {title: '用户名', key: 'username', width: 130},
  {title: '姓名', key: 'realName', width: 130},
  // {title: 'Email', key: 'email', width: 150},
  // {title: '电话', key: 'phone', width: 130},
  {title: '单位', key: 'officeName', width: 150},
  {title: '区域', key: 'areaName', width: 130},
  {title: '添加时间', slot: 'date', width: 180},
  {title: '状态', slot: 'status', width: 80},
  {title: '操作', slot: 'action'},
]
export default Vue.extend({
  data() {
    return {
      loading: false,
      pageNum: 1,
      pageSize: 10,
      total: 1,
      columns,
      data: {
        total: 0,
        list: [],
      },
    }
  },
  methods: {
    getData() {
      this.loading = true
      const { pageNum, pageSize } = this
      const params = { pageNum, pageSize }
      request.get('/api/sys/user/page', params)
      .then((data: any) => {
        this.data = data
        this.loading = false
      })
    },
    deleteItem(id: string) {
      request.del(`/api/sys/user/${id}`)
      .then(() => {
        this.$Message.success('删除成功!')
        this.getData()
      })
    },
    /* 改变pageNum */
    changPageNum(num: number) {
      this.pageNum = num
      this.getData()
    },
  },
  mounted() {
    this.getData()
  },
})
